import styled from 'styled-components';

import { Button } from '@core/UI/Button';
import { Typography } from '@core/UI/Typography';
import { BREAKPOINTS } from '@core/constants/styles';

import { IAuthenticationErrorLabelStylesProps } from './types';

export const Form = styled.form`
  align-self: stretch;

  @media ${BREAKPOINTS.TABLET} {
    padding: 0;
  }
`;

export const SubmitButton = styled(Button).attrs(() => ({ htmlType: 'submit' }))`
  width: 100%;
  margin-top: 12px;
  font-weight: 700;
`;

export const AuthenticationErrorLabel = styled(Typography).attrs(() => ({ type: 'p3' }))<IAuthenticationErrorLabelStylesProps>`
  margin-top: 8px;
  visibility: ${({ isShown }) => (isShown ? 'visible' : 'hidden')};
`;
