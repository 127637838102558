import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Input } from '@core/UI/Input';
import { ROUTES } from '@core/constants/routes';
import { useAppDispatch } from '@core/store';

import { fetchSignIn, selectAuthenticationState } from '@features/authentication/store';

import * as UI from './LoginForm.styles';
import { schema } from './schema';
import { IAuthenticationInput } from './types';

const LoginForm = () => {
  const dispatch = useAppDispatch();

  const { meta } = useSelector(selectAuthenticationState);
  const { fetchLoading, fetchError } = meta;

  const router = useRouter();

  const { control, handleSubmit, formState } = useForm<IAuthenticationInput>({ resolver: yupResolver(schema) });
  const { errors } = formState;

  const onSubmit: SubmitHandler<IAuthenticationInput> = async (data) => {
    const { login, password } = data;

    try {
      await dispatch(fetchSignIn({ login, password })).unwrap();
      router.push(ROUTES.STRATEGY);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <UI.Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        defaultValue=""
        name="login"
        render={({ field }) => (
          <Input
            {...field}
            error={errors.login?.message}
            label="Логин"
            placeholder="Введите логин"
          />
        )}
      />

      <Controller
        control={control}
        defaultValue=""
        name="password"
        render={({ field }) => (
          <Input
            {...field}
            error={errors.password?.message}
            htmlType="password"
            label="Пароль"
            placeholder="Введите пароль"
          />
        )}
      />

      <UI.SubmitButton loading={fetchLoading}>
        Войти
      </UI.SubmitButton>

      <UI.AuthenticationErrorLabel isShown={fetchError}>
        Неверная связка логин/пароль
      </UI.AuthenticationErrorLabel>
    </UI.Form>
  );
};

export default LoginForm;
