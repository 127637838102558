import LoginForm from '@features/authentication/components/LoginForm';

import * as UI from './Authentication.styles';

const Authentication = () => (
  <UI.Wrapper>
    <UI.FormContainer>
      <UI.Logo />

      <UI.Heading>
        Вход в личный кабинет
      </UI.Heading>

      <LoginForm />
    </UI.FormContainer>
  </UI.Wrapper>
);

export default Authentication;
