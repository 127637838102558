import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';
import { BREAKPOINTS, PAGE_PADDINGS } from '@core/constants/styles';

import emblem from '@features/authentication/assets/emblem.png';
import logoMini from '@features/authentication/assets/logo-mini.png';
import logo from '@features/authentication/assets/logo.png';
import sidebarBackground from '@features/authentication/assets/sidebar.png';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media ${BREAKPOINTS.DESKTOP_LG} {
    flex-direction: row;
    align-items: center;
    height: 100vh;
  }

  &::before {
    content: '';
    height: 200px;
    width: 100%;
    background: url(${sidebarBackground.src}) no-repeat center;
    background-size: cover;

    @media ${BREAKPOINTS.TABLET} {
      height: 411px;
    }

    @media ${BREAKPOINTS.DESKTOP_LG} {
      width: 529px;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      z-index: -1;
    }
  }

  &::after {
    position: absolute;
    z-index: -1;
    top: 100%;
    right: -149px;
    height: 549px;
    width: 549px;
    transform: translateY(-100%);
    background: url(${emblem.src}) no-repeat;
    background-size: contain;

    @media ${BREAKPOINTS.DESKTOP_LG} {
      content: '';
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${PAGE_PADDINGS.MOBILE}px ${PAGE_PADDINGS.MOBILE}px;

  @media ${BREAKPOINTS.TABLET} {
    align-items: stretch;
    padding: 0 ${PAGE_PADDINGS.TABLET}px ${PAGE_PADDINGS.TABLET}px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    position: relative;
    width: 347px;
    margin: 0 auto;
    padding: 0;
  }
`;

export const Logo = styled.div`
  width: 104px;
  height: 88px;
  margin: 24px 0;
  background-image: url(${logoMini.src});
  background-size: contain;

  @media ${BREAKPOINTS.TABLET} {
    width: 298px;
    height: 98px;
    background-image: url(${logo.src});
    margin: 48px 0;
  }
`;

export const Heading = styled(Typography).attrs({ type: 'h1', color: 'riverBlue' })`
  font-weight: normal;
  margin-bottom: 16px;

  @media ${BREAKPOINTS.TABLET} {
    margin-bottom: 32px;
  }
`;
